<template>
  <div class="w-2/4 inline-block">
    <label
      v-if="labelup"
      :for="props.idfrom"
      :class="['cursor-pointer text-base mb-1 block font-semibold']"
    >
      <UiLanguagePlaceholder domain="UiDaterangeInput" name="from" />
      <span v-if="props.mandatoryFields">*</span>
    </label>
    <div class="block relative" @click="resetTo(false)">
      <span class="absolute top-0 left-0 py-2 ml-3 h-full flex items-center">
        <UiIcon src="/svg/icons/calendar.svg" width="22" height="22" class="fill-neutral-500" />
      </span>
      <input
        :id="props.idfrom"
        :value="props.from"
        :class="[
          { 'pl-11 pr-3 py-3 h-[55px]': normal && labelup},
          { 'py-2': slim && labelup},
          { 'pl-11 pr-3 pt-6 pb-1 h-[55px] text-lg font-semibold peer': normal && !labelup},
          { 'pl-11 pr-6 pt-3 pb-1 text-base font-semibold peer': slim && !labelup},
          'border border-neutral-200 rounded-l-xl bg-white text-left w-full focus:outline-none focus:border-petrol-500 focus:ring-1 focus:ring-petrol-500 cursor-pointer'
        ]"
        :name="props.nameFrom"
        readonly="readonly"
        placeholder=" "
        @input="updateFrom($event.target.value)"
      >
      <label
        v-if="label"
        :for="props.idfrom"
        :class="[
          { 'absolute text-lg text-petrol-500 font-semibold duration-200 transform -translate-y-3.5 scale-[75%] top-4 z-10 origin-[0] left-11 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1 peer-focus:scale-[75%] peer-focus:-translate-y-3.5': slim},
          { 'absolute text-lg text-petrol-500 font-semibold duration-200 transform -translate-y-2.5 scale-[85%] top-4 z-10 origin-[0] left-11 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[85%] peer-focus:-translate-y-2.5': normal},
          'left-0 cursor-pointer'
        ]"
      >
        <UiLanguagePlaceholder domain="UiDaterangeInput" name="from" />
        <span v-if="props.mandatoryFields">*</span>
      </label>
    </div>
  </div>
  <div class="w-2/4 inline-block">
    <label
      v-if="labelup"
      :for="props.idto"
      :class="['cursor-pointer text-base mb-1 block font-semibold']"
    >
      <UiLanguagePlaceholder domain="UiDaterangeInput" name="to" />
      <span v-if="props.mandatoryFields">*</span>
    </label>
    <div class="block relative" @click="resetTo">
      <span class="absolute top-0 left-0 py-2 ml-3 h-full flex items-center">
        <UiIcon src="/svg/icons/calendar.svg" width="22" height="22" class="fill-neutral-500" />
      </span>
      <input
        :id="props.idto"
        :value="props.to"
        :class="[
          { 'pl-11 pr-3 py-3 h-[55px]': normal && labelup},
          { 'py-2': slim && labelup},
          { 'pl-11 pr-3 pt-6 pb-1 h-[55px] text-lg font-semibold peer': normal && !labelup},
          { 'pl-11 pr-6 pt-3 pb-1 text-base font-semibold peer': slim && !labelup},
          'border border-neutral-200 border-l-transparent rounded-r-xl bg-white text-left w-full focus:outline-none focus:border-petrol-500 focus:ring-1 focus:ring-petrol-500 cursor-pointer'
        ]"
        :name="props.nameTo"
        readonly="readonly"
        placeholder=" "
        @input="updateTo($event.target.value)"
      >
      <label
        v-if="label"
        :for="props.idto"
        :class="[
          { 'absolute text-lg text-petrol-500 font-semibold duration-200 transform -translate-y-3.5 scale-[75%] top-4 z-10 origin-[0] left-11 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1 peer-focus:scale-[75%] peer-focus:-translate-y-3.5': slim},
          { 'absolute text-lg text-petrol-500 font-semibold duration-200 transform -translate-y-2.5 scale-[85%] top-4 z-10 origin-[0] left-11 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-[85%] peer-focus:-translate-y-2.5': normal},
          'left-0 cursor-pointer'
        ]"
      >
        <UiLanguagePlaceholder domain="UiDaterangeInput" name="to" />
        <span v-if="props.mandatoryFields">*</span>
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getRandomId } from '~/utils/Utils'
import UiIcon from '~/components/ui/UiIcon.vue'

const props = defineProps({
  design: {
    type: String,
    default: 'normal'
  },
  label: {
    type: String,
    default: ''
  },
  placeholderFrom: {
    type: String,
    default: ''
  },
  placeholderTo: {
    type: String,
    default: ''
  },
  nameFrom: {
    type: String,
    default: 'von'
  },
  nameTo: {
    type: String,
    default: 'bis'
  },
  idfrom: {
    type: String,
    default: getRandomId
  },
  idto: {
    type: String,
    default: getRandomId
  },
  from: {
    type: String,
    default: ''
  },
  to: {
    type: String,
    default: ''
  },
  mandatoryFields: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update:from', 'update:to', 'updateTemporaryBis'])

// Label choices
const label = computed(() => props.label === 'normal')
const labelup = computed(() => props.label === 'up')

// Design choices
const normal = computed(() => props.design === 'normal')
const slim = computed(() => props.design === 'slim')

const updateFrom = (date) => {
  emit('update:from', date)
}

const updateTo = (date) => {
  emit('update:to', date)
}

function resetTo (status = true) {
  if (status) {
    emit('updateTemporaryBis', null)
    emit('update:to', null)
  } else {
    emit('updateTemporaryBis', props.to)
  }
}
</script>

<style scoped>
 .label-up-bg{
   background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 57%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
   background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 40%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 57%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
   background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 40%,rgba(255,255,255,1) 50%,rgba(255,255,255,0) 57%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
 }
</style>
