<template>
  <UiModal
    v-if="isModal"
    ref="modal"
    :render-if-hidden="props.renderIfHidden"
    @shown="modalOrDropdownShown()"
    @hidden="modalOrDropdownHidden()"
  >
    <template v-if="$slots.trigger" #trigger>
      <slot name="trigger" />
    </template>
    <template #head>
      <slot v-if="$slots.head" name="head" />
    </template>
    <template #default>
      <slot name="default" />
    </template>
    <template v-if="$slots.footerClose" #footerClose>
      <slot name="footerClose" />
    </template>
    <template v-if="$slots.foot" #foot>
      <slot name="foot" />
    </template>
  </UiModal>
  <UiDropdown
    v-if="!isModal"
    ref="dropdown"
    :custom-design-head="props.designHead"
    :custom-design="props.design"
    :custom-design-footer="props.customDesignFooter"
    @update:is-open="updateDropdownState"
    @shown="modalOrDropdownShown()"
    @hidden="modalOrDropdownHidden()"
  >
    <template v-if="$slots.trigger" #trigger>
      <slot name="trigger" />
    </template>
    <template v-if="$slots.head" #head>
      <slot name="head" />
    </template>
    <template #default>
      <slot name="default" />
    </template>
    <template v-if="$slots.footerClose" #footerClose>
      <slot name="footerClose" />
    </template>
    <template v-if="$slots.foot" #foot>
      <slot name="foot" />
    </template>
  </UiDropdown>
</template>

<script setup>
const emit = defineEmits(['shown', 'hidden', 'update:isOpen'])

const props = defineProps({
  breakpoint: {
    type: Number,
    default: 768
  },
  renderIfHidden: {
    type: Boolean,
    default: true
  },
  design: {
    type: String,
    default: ''
  },
  designHead: {
    type: String,
    default: ''
  },
  customDesignFooter: {
    type: String,
    default: ''
  }
})

const modal = ref()
const dropdown = ref()
const show = () => {
  modal.value?.show()
  dropdown.value?.show()
  window.history.pushState({ modalOpen: true, hash: '' }, '')
}

const hide = () => {
  modal.value?.hide()
  dropdown.value?.hide()
}

const toggle = () => {
  modal.value?.show()
  dropdown.value?.toggle()
}

const modalOrDropdownShown = () => {
  emit('shown')
}

const modalOrDropdownHidden = () => {
  emit('hidden')
}

const isModal = ref(true)
const isOpen = computed(() => Boolean(modal.value?.isOpen || dropdown.value?.isOpen))
const updateDropdownState = (newState) => {
  emit('update:isOpen', newState)
}

defineExpose({
  show,
  hide,
  toggle,
  isOpen,
  isModal
})

const listener = (event) => {
  isModal.value = event.matches
}

let mediaQuery
if (import.meta.client) {
  mediaQuery = window.matchMedia(`(max-width: ${props.breakpoint}px)`)
}

onMounted(() => {
  isModal.value = mediaQuery?.matches
  mediaQuery?.addEventListener('change', listener)
})

onUnmounted(() => {
  mediaQuery?.removeEventListener('change', listener)
})

if (import.meta.client) {
  window.addEventListener('popstate', (event) => {
    if (isModal.value !== 0) {
      event.preventDefault()
      const inputElements = document.querySelectorAll('input')
      inputElements.forEach((input) => {
        input.blur()
      })
      hide()
      isModal.value = mediaQuery?.matches
      mediaQuery?.addEventListener('change', listener)
    } else {
      window.location.href = event.target.location.href
    }
  })
}
</script>
<style lang="scss">
.popper-position-w-full{
  width: 100% !important;
  margin: 0 !important;
  border: none !important;
}
</style>
